import i18n from 'i18next';
import lodash from 'lodash';
import { action, computed, makeAutoObservable, runInAction } from 'mobx';
import { BackendService } from 'services';

import { DataGridStore, IDataGridStoreData, IDataGridStoreState } from '@uk';

import { ERequestFilterPredicate, IFilterData, IRequestFilterData, IRequestFilterField } from '../filter/i-filter';
import { ISummaryInfoInRecord } from '../summary/types';
import { CWordDictionary, IWordDictionary } from '../wordDictionary/WordDictionary.types';

import { recordsGridColumns } from './records-grid-columns';
import { ERecordStatus, IKwsIndex, ILabel, IRecord, TRecordFields } from './types';

import {
  addDateField,
  addOwnerField,
  addStatusField,
  addTextField,
  addTranslateStatusField,
  convertPredicate,
  filterDataToDictorsField,
  filterDataToSummaryField,
} from './utils/filterDataToRequest';

import { gAPP_STORE } from '@/app/app-store';

import { EntityChangeEventActions, EntityChangeEventTypes } from '@/services/events/types';
import { gridStorageService } from '@/services/grid-storage/grid-storage-service';
import { summaryService } from '@/services/summary/summary-service';
import { ITopicDto } from '@/services/summary/types';
import { EntityChangeGridStoreWrapper } from '@/stores/entityChangeGridStoreWrapper';
import { IGridStorageEntry } from '@/types/grid-storage';

interface IUploadingRecord {
  id: string;
  name: string;
  status: ERecordStatus;
}

interface IRecordsData {
  records: IRecord[];
  hasNextPage: boolean;
  loaded: number[];
  uploadingRecordlist: IUploadingRecord[];
}

const recordsDataInitialState: IRecordsData = {
  records: [],
  hasNextPage: false,
  loaded: [],
  uploadingRecordlist: [],
};

export class RecordsStore {
  mapSummaryTopic = new Map<number, ITopicDto[]>();
  data: IRecordsData = recordsDataInitialState;
  gridStoreEventWrapper: EntityChangeGridStoreWrapper<
    number,
    IRecord,
    IRecord | IKwsIndex | IGridStorageEntry | ILabel
  >;
  gridStore: DataGridStore<IRecord>;
  recordsCurrent: IRecord[] = [];

  constructor() {
    const cols = gAPP_STORE.viewTranslate
      ? recordsGridColumns()
      : recordsGridColumns().filter(c => c.id !== 'translationStatus');

    this.gridStore = new DataGridStore<IRecord>(
      'recordGrid',
      cols,
      {
        dataProvider: async (state: IDataGridStoreState) => {
          await this.getAll(state.offset, state.limit);

          const result = { data: this.data.records, hasNextPage: this.data.hasNextPage };

          return result;
        },
      },
      undefined,
      undefined,
      undefined,
      !gAPP_STORE.dataGridAnswer,
    );

    this.gridStoreEventWrapper = new EntityChangeGridStoreWrapper<
      number,
      IRecord,
      IRecord | IKwsIndex | IGridStorageEntry
    >(
      [
        { action: EntityChangeEventActions.UPDATE, type: EntityChangeEventTypes.RECORD },
        { action: EntityChangeEventActions.CREATE, type: EntityChangeEventTypes.KWS_INDEX },
        { action: EntityChangeEventActions.UPDATE, type: EntityChangeEventTypes.KWS_INDEX },
        { action: EntityChangeEventActions.DELETE, type: EntityChangeEventTypes.KWS_INDEX },
        { action: EntityChangeEventActions.CREATE, type: EntityChangeEventTypes.GROUP_TO_RECORD },
        { action: EntityChangeEventActions.UPDATE, type: EntityChangeEventTypes.GROUP_TO_RECORD },
        { action: EntityChangeEventActions.DELETE, type: EntityChangeEventTypes.GROUP_TO_RECORD },
        { action: EntityChangeEventActions.CREATE, type: EntityChangeEventTypes.STORAGE_ENTRY },
        { action: EntityChangeEventActions.UPDATE, type: EntityChangeEventTypes.STORAGE_ENTRY },
        { action: EntityChangeEventActions.DELETE, type: EntityChangeEventTypes.STORAGE_ENTRY },
        { action: EntityChangeEventActions.CREATE, type: EntityChangeEventTypes.LABEL_TYPE },
        { action: EntityChangeEventActions.UPDATE, type: EntityChangeEventTypes.LABEL_TYPE },
        { action: EntityChangeEventActions.DELETE, type: EntityChangeEventTypes.LABEL_TYPE },
        { action: EntityChangeEventActions.CREATE, type: EntityChangeEventTypes.LABEL_SHORT },
        { action: EntityChangeEventActions.UPDATE, type: EntityChangeEventTypes.LABEL_SHORT },
        { action: EntityChangeEventActions.DELETE, type: EntityChangeEventTypes.LABEL_SHORT },
      ],
      this.gridStore,
      async (orig, event) => {
        if (event.type === EntityChangeEventTypes.RECORD) {
          console.log('Update record: ', orig.id);
          runInAction(() => {
            Object.assign(orig, event.value);
            this.parseHotlist(orig);
          });
        }
        if (event.type === EntityChangeEventTypes.LABEL_SHORT) {
          switch (event.action) {
            case 'add':
              await this.mapLabelsToRecords([orig]);
              break;
            case 'del':
              orig.labelLists = orig.labelLists?.filter(lbl => lbl.id !== event.value.id);
              break;
            case 'upd':
              const label = orig.labelLists?.find(lbl => lbl.id === event.value.id);
              if (label) {
                label.comment = (event.value as ILabel).comment;
              }
          }
          console.log('Update record LABEL(SHORT): ', orig.id);
        }
        if (event.type === EntityChangeEventTypes.LABEL_TYPE) {
          console.log('Update GRID-LABEL: ', orig.id);
        }
        if (event.type === EntityChangeEventTypes.KWS_INDEX) {
          await this.mapTopicsToRecords([orig]);
          console.log('Update record topics: ', orig.id);
        }

        if (event.type === EntityChangeEventTypes.GROUP_TO_RECORD) {
          await this.mapGroupsToRecords([orig]);
          console.log('Update record groups: ', orig.id);
        }

        if (event.type === EntityChangeEventTypes.STORAGE_ENTRY) {
          await this.mapFilesToRecords([orig]);
          console.log('Update record files: ', orig.id);
        }

        return Promise.resolve();
      },
    );

    makeAutoObservable(this, undefined, { autoBind: true });
  }

  setCheckedRows(checkedRows: number[]) {
    this.gridStore.setCheckedRows(checkedRows);
  }

  setGridStore(gridStore: IDataGridStoreData<IRecord>, hasNextPage: boolean, currentPage: number, rowsOnPage: number) {
    this.setDataGridStore(gridStore, hasNextPage, currentPage, rowsOnPage);
  }

  setDataGridStore = (
    data: IDataGridStoreData<IRecord>,
    hasNextPage: boolean,
    currentPage: number,
    rowsOnPage: number,
  ) => {
    this.gridStore.data = data.data;
    this.gridStore.hasNextPage = hasNextPage;
    this.gridStore.currentPage = currentPage;
    this.gridStore.rowsOnPage = rowsOnPage;
  };

  filterDataToRequestFields(filterData: IFilterData): IRequestFilterField<TRecordFields>[] {
    const fields: IRequestFilterField<TRecordFields>[] = [];

    // Status
    addStatusField<TRecordFields>(
      (filterData.status?.value as string[]) || [],
      'status',
      convertPredicate(filterData.status?.predicate),
      fields,
    );

    // TranslateStatus
    addTranslateStatusField<TRecordFields>(
      (filterData.translationStatus?.value as string[]) || [],
      'translationStatus',
      convertPredicate(filterData.translationStatus?.predicate),
      fields,
    );

    // Case ID
    addTextField<TRecordFields>(
      (filterData.caseid?.value as string[]) || [],
      'caseid',
      convertPredicate(filterData.caseid?.predicate),
      fields,
    );
    // File name
    addTextField(
      (filterData.fileName?.value as string[]) || [],
      'fileName',
      convertPredicate(filterData.fileName?.predicate),
      fields,
    );

    // Date load from
    addDateField<TRecordFields>(
      filterData.loadFrom?.value?.toString() || '',
      'loadDate',
      ERequestFilterPredicate.EQ_GR,
      fields,
    );
    // Date load to
    addDateField<TRecordFields>(
      filterData.loadTo?.value?.toString() || '',
      'loadDate',
      ERequestFilterPredicate.EQ_LESS,
      fields,
    );
    // Date create from
    addDateField<TRecordFields>(
      filterData.createFrom?.value?.toString() || '',
      'createdDate',
      ERequestFilterPredicate.EQ_GR,
      fields,
    );
    // Date create to
    addDateField<TRecordFields>(
      filterData.createTo?.value?.toString() || '',
      'createdDate',
      ERequestFilterPredicate.EQ_LESS,
      fields,
    );
    // Language
    addTextField(
      ((filterData.languageResolved?.value as string[]) || []).map(l => (l || '').toString().toLowerCase()),
      'languageResolved',
      convertPredicate(filterData.languageResolved?.predicate),
      fields,
    );
    // Owner
    addOwnerField(
      (filterData.owner?.value as string[]) || [],
      'ownerId',
      convertPredicate(filterData.owner?.predicate),
      fields,
    );

    // Text
    addTextField(
      ((filterData.textIdx?.value as string[]) || []).map(s => s.toLowerCase()) || [],
      'textIdx',
      convertPredicate(filterData.textIdx?.predicate),
      fields,
    );

    // Translation
    addTextField(
      ((filterData.translateIdx?.value as string[]) || []).map(s => s.toLowerCase()) || [],
      'translateIdx',
      convertPredicate(filterData.translateIdx?.predicate),
      fields,
    );

    return fields;
  }

  getRecordTranslationStatus() {
    const record = this.gridStore.selectedRow;

    return record && record.summaryTranslationStatus;
  }

  getRecordTextTranslationStatus() {
    const record = this.gridStore.selectedRow;

    return record?.translationStatus;
  }

  getStorage(data: string) {
    const recordsStoreData = localStorage.getItem(data);
    const dataStorage = recordsStoreData ? JSON.parse(recordsStoreData) : null;

    return dataStorage;
  }

  reload = async () => {
    gAPP_STORE.videoStore.reset();
    this.gridStore.resetSelected();
    await this.gridStore.reload();
    //subscrabe on observed data:
  };

  private createRequestFilter = (offset: number, limit: number): IRequestFilterData<TRecordFields> => {
    const filterFields = this.filterDataToRequestFields(gAPP_STORE.getFilterStore().Data);

    const filterData: IRequestFilterData<TRecordFields> = {
      fields: filterFields,
      limit,
      offset,
      sortByField: { name: 'id', order: 'Dsc' },
    };

    // Topics
    const topicIds: number[] = [];
    const topics = gAPP_STORE.getFilterStore().Data.topic?.value;
    if (Array.isArray(topics)) {
      topicIds.push(...topics.map(t => +t));
      if (topicIds.length > 0) {
        filterData.themes = {
          ids: topicIds,
          threshold: (gAPP_STORE.getFilterStore().Data.topicThreshold?.value as number) || 50,
        };
      }
    }
    // Dictors
    const dictorsFilter = filterDataToDictorsField(gAPP_STORE.getFilterStore().Data);
    if (dictorsFilter) {
      filterData.dictors = dictorsFilter;
    }

    const summaryFilter = filterDataToSummaryField(gAPP_STORE.getFilterStore().Data);
    if (summaryFilter) {
      console.log('~~~ тут поиск по суммаризации~~', summaryFilter);
      filterData.summaryFilter = summaryFilter;
    }

    return filterData;
  };

  private summaryClear() {
    this.mapSummaryTopic.clear();
    gAPP_STORE.summaryStore.clear();
  }

  private getRecordsData = async (
    offset = 0,
    limit = 0,
    byIds?: number[],
  ): Promise<{
    data: IRecord[];
    hasNextPage: boolean;
  } | null> => {
    if (limit === 0) {
      return null;
    }

    this.summaryClear();

    try {
      const records: IRecord[] = [];

      if (!byIds) {
        const filter = this.createRequestFilter(offset, limit + 1);
        const resp = await BackendService.post('file/filter', JSON.stringify(filter));
        records.push(...(resp || []));
      } else {
        const resp = await this.getRecordsById(byIds);
        records.push(...(resp || []));
      }

      if (records.length > 0) {
        await Promise.allSettled([
          this.mapFilesToRecords(records),
          this.mapLabelsToRecords(records),
          this.mapHotlistsToRecords(records),
          this.mapGroupsToRecords(records),
          this.mapTopicsToRecords(records),
          this.mapAISummaryToRecords(records),
        ]);
      }

      return { data: records.slice(0, limit), hasNextPage: records.length > limit };
    } catch (ex) {
      console.error('Error loading records, ex = ', ex);

      return null;
    }
  };

  async getlabelsByRecords(recordIds: number[]) {
    try {
      return recordIds.length > 0 ? await BackendService.get(`labels/by/records?recordIds=${recordIds.join(',')}`) : [];
    } catch (e) {
      console.log('Get Labels For Records error:', { error: e });

      return [];
    }
  }

  async mapLabelsToRecords(records: IRecord[]) {
    const allLabels = await this.getlabelsByRecords(records.map(record => record.id));
    runInAction(() => {
      records.forEach(record => {
        record.labelLists = allLabels[record.id];
      });
    });
  }

  async mapFilesToRecords(records: IRecord[]) {
    try {
      const files = await gridStorageService.getRecordsFiles(records.map(record => record.correlationId));
      if (files) {
        runInAction(() => {
          records.forEach(record => {
            record.__audioStorageKey = files.find(
              f => f.correlationId === record.correlationId && f.type === 1,
            )?.storageKey;
            record.__videoStorageKey = files.find(
              f => f.correlationId === record.correlationId && f.type === 5,
            )?.storageKey;
            record.__waveformStorageKey = files.find(
              f => f.correlationId === record.correlationId && f.type === 6,
            )?.storageKey;
          });
        });
      }
    } catch (ex) {
      console.error('Error loading records files, ex = ', ex);
    }
  }

  private parseHotlist(record: IRecord) {
    if (record.hotlists && record.hotlists !== '') {
      try {
        record.hotLists = JSON.parse(record.hotlists);
      } catch {
        record.hotLists = [];
      }
    } else {
      record.hotLists = [];
    }
  }

  async mapHotlistsToRecords(records: IRecord[]) {
    records.forEach(record => this.parseHotlist(record));

    return await Promise.resolve();
  }

  async getSummaryTopicsByRecords(recordIds: number[]): Promise<ISummaryInfoInRecord[]> {
    try {
      return recordIds.length > 0
        ? await BackendService.get(`summary/topic/count?recordIds=${recordIds.join(',')}`)
        : [];
    } catch (e) {
      console.log('Get Summary Topic Count For Records error:', { error: e });

      return [];
    }
  }

  async mapAISummaryToRecords(records: IRecord[]) {
    const aiFields = await this.getSummaryTopicsByRecords(records.map(record => record.id));
    const ids = aiFields.map(item => item.recordId);

    runInAction(() => {
      records.forEach(record => {
        const ind = ids.indexOf(record.id);
        if (ind >= 0) {
          record.taskQuantity = aiFields[ind].hasTasks;
          record.topicCount = aiFields[ind].topicCount;
        }
      });
    });
  }

  async mapGroupsToRecords(records: IRecord[]) {
    try {
      const groups = await gAPP_STORE.getGroupsStore().getRecordGroups(records.map(record => record.id));

      if (groups) {
        await gAPP_STORE.getGroupsStore().getAll();

        const userGroupsIds = (gAPP_STORE.loginStore.user?.groups || []).map(g => g.id);

        runInAction(() => {
          records.forEach(record => {
            record.groups = groups[record.id];

            // Если текущий пользователь не супервизор, то оставляем только те группы, в которых он состоит
            if (!gAPP_STORE.loginStore.user?.isSupervisor && !gAPP_STORE.loginStore.user?.isAdmin) {
              record.groups = record.groups?.filter(g => userGroupsIds.includes(g.id));
            }
          });
        });
      } else {
        records.forEach(record => {
          record.groups = undefined;
        });
      }
    } catch (ex) {
      console.error('Error loading records groups, ex = ', ex);
    }
  }

  async mapTopicsToRecords(records: IRecord[]) {
    if (gAPP_STORE.avocado_topics) {
      try {
        const recordsTopics = await gAPP_STORE
          .getWordDictionaryStore()
          .getRecordTopics(records.map(record => record.id));

        if (recordsTopics) {
          runInAction(() => {
            const allTopics = gAPP_STORE.getWordDictionaryStore().data.wordDictionaryList;

            records.forEach(record => {
              const recordTopics = recordsTopics.filter(
                t => Math.round(t.kwsThreshold || 0) > 0 && t.recordId === record.id,
              );

              record.topics = recordTopics
                .filter(t => allTopics.map(at => at.id).includes(t.dictionaryId))
                .map(t => {
                  const wd: IWordDictionary = {
                    ...(allTopics.find(at => at.id === t.dictionaryId) || new CWordDictionary()),
                  };
                  wd.kwsThreshold = t.kwsThreshold;

                  return wd;
                });
            });
          });
        }
      } catch (ex) {
        console.error('Error loading records topics, ex = ', ex);
      }
    }
  }

  getAllRecords = (records: IRecord[]) => {
    this.recordsCurrent = records;
  };

  /**
   * Get all files
   */
  private getAll = async (offset = 0, limit = 0) => {
    const result = await this.getRecordsData(offset, limit);

    runInAction(() => {
      if (result) {
        this.data.records = result.data;
        this.data.hasNextPage = result.hasNextPage;
      } else {
        console.error('RecordsStore.getAll()');
        this.data.records = [];
      }
    });
  };

  @action
  clear = () => {
    this.summaryClear();
    this.gridStore.reset();
    this.data = lodash.cloneDeep(recordsDataInitialState);
  };

  // @action
  // getStatsData = async () => {
  //   await this.getTotalForAllUsers();
  //   await this.getRecordCountsForUser();
  //   //await this.refreshGrid();
  // };

  getRecordsById = async (ids: number[]) => {
    return await BackendService.post('file/list', JSON.stringify(ids));
  };

  /**
   * Upload files
   *
   * @param file
   */
  uploadRecord = async (id: number, file: File) => {
    // upload record data
    // const uploadData = new FormData();
    // uploadData.append('file', file);
    // await BackendService.put(`file/upload/${registerResponse.id}`, uploadData, true);
    this.gridStore.reload();
    await BackendService.put(`file/upload/${id}`, file, true);
  };

  /**
   * Delete records
   */
  delete = async (record: IRecord) => {
    try {
      await BackendService.delete(`file/${record.id.toString()}`);
      if (gAPP_STORE.soundStore.record?.id === record.id) {
        gAPP_STORE.soundStore.resetRecord();
        gAPP_STORE.videoStore.reset();
      }
      await this.reload();
    } catch (error) {}
  };

  changeMapSummaryTopic(key: number, data: ITopicDto[]) {
    this.mapSummaryTopic.set(key, data);
  }

  async loadSummaryTopicForRecord(recordId: number) {
    const summaryDtoRevisions = await summaryService.getBrief(recordId);
    const lastRevision = summaryDtoRevisions.length - 1;
    if (lastRevision < 0) {
      return;
    }

    const topicsDto = await summaryService.getTopics(summaryDtoRevisions[lastRevision].id);

    this.changeMapSummaryTopic(recordId, topicsDto);
  }

  /**
   * Get total for all users
   */
  // @action
  // getTotalForAllUsers = async () => {
  //   try {
  //     const data = await BackendService.get('file/total');
  //     runInAction(() => (this.data.totalForAllUsers = data));
  //   } catch (error) {
  //     runInAction(() => (this.data.totalForAllUsers = 0));
  //   }
  // };

  /**
   * Get record counts for user
   */
  // @action
  // getRecordCountsForUser = async () => {
  //   try {
  //     const data = await BackendService.get('file/stats');
  //     runInAction(
  //       () =>
  //         // eslint-disable-next-line @typescript-eslint/no-explicit-any
  //         (this.data.recordCountsForUser = data.sort((a: any, b: any) =>
  //           a.status === b.status ? 0 : a.status - b.status,
  //         )),
  //     ); // sort data by status
  //   } catch (error) {
  //     this.data.recordCountsForUser = [];
  //   }
  // };

  /**
   * Возвращает выделенные записи из таблицы - выделенную единично курсором или все, выделенные чекбоксами
   */
  get selectedRecords(): IRecord[] {
    const records: IRecord[] = [];

    if (this.gridStore.getCheckedRows().length > 0) {
      records.push(...this.gridStore.getCheckedRows());
    } else {
      records.push(...this.gridStore.getSelectedRows());
    }

    return records;
  }

  @computed summaryTopicTipsById(id: number) {
    return this.mapSummaryTopic.has(id)
      ? this.mapSummaryTopic.get(id)?.map(topic => {
          const text = topic.title ?? topic.id.toString();

          return text;
        })
      : i18n.t('summary.topicLoading');
  }

  getTopicList(id: number) {
    return this.mapSummaryTopic.has(id) ? this.mapSummaryTopic.get(id)?.map(({ title }) => title || '--') : '';
  }
}
