import { useState } from 'react';

import { Box } from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';

import EditIcon from '@material-ui/icons/Edit';

//import ClearRoundedIcon from '@material-ui/icons/ClearRounded';

import { useTranslation } from 'react-i18next';

import { observer } from 'mobx-react';

import { DataGrid, IToolbarExDescriptorItem, Panel, ToolbarEx } from '@uk';

import { toast } from 'react-toastify';

import UserDialog, { IUserDialogResult } from './user-dialog';
import { usersGridColumns } from './users-grid-columns';

import { CUser } from './i-user';

import { gAPP_STORE } from '@/app/app-store';

const UsersGrid = () => {
  const { t } = useTranslation();

  let cols = usersGridColumns;
  if (!gAPP_STORE.groups) {
    cols = cols.filter(col => col.id !== 'groups');
  }

  const [openUserDialog, setOpenCreateUserDialog] = useState(false);

  const [dialogResult, setDialogResult] = useState<IUserDialogResult>({
    user: new CUser(),
    newGroups: [],
    closeStatus: 0,
  });

  const handleCloseUserDialog = async (dialogResult: IUserDialogResult) => {
    setOpenCreateUserDialog(false);

    if (dialogResult.closeStatus === 1) {
      const userStore = gAPP_STORE.getUsersStore();
      if (dialogResult.user.id !== -1 && dialogResult.oldValue) {
        // Update user
        await userStore.update(dialogResult.user, dialogResult.oldValue);
        if (
          dialogResult.password &&
          dialogResult.password.length > 0
          // && dialogResult.password === dialogResult.passwordConfirm
        ) {
          // Update user password
          await userStore.updatePassword(dialogResult.user, dialogResult.password);
        }

        // Change user groups
        const oldGroupIds = (dialogResult.user.groups || []).map(g => g.id);
        const newGroupIds = (dialogResult.newGroups || []).map(g => g.id);

        const addedGroupsIds = newGroupIds.filter(newId => oldGroupIds.findIndex(oldId => oldId === newId) < 0);
        const removedGroupsIds = oldGroupIds.filter(oldId => newGroupIds.findIndex(newId => oldId === newId) < 0);
        if (addedGroupsIds.length > 0 || removedGroupsIds.length > 0) {
          await gAPP_STORE.getGroupsStore().updateUserGroups(dialogResult.user.id, removedGroupsIds, addedGroupsIds);
        }
        await userStore.gridStore.reload();
      } else {
        // Create user
        const newUser = await userStore.create(dialogResult.user, dialogResult.password || '');
        if (newUser) {
          await gAPP_STORE.getGroupsStore().addGroupsToUser(
            newUser.id,
            dialogResult.newGroups.map(g => g.id),
          );
          await userStore.gridStore.reload();
        }
      }
      // usersStore.create();
    }
  };

  const handleCreateUser = () => {
    setDialogResult({ user: new CUser(), newGroups: [], closeStatus: 0 });
    setOpenCreateUserDialog(true);
  };

  const handleEditUser = () => {
    const selectedUser = gAPP_STORE.getUsersStore().gridStore.selectedRow;
    if (selectedUser) {
      if (selectedUser.id === gAPP_STORE.loginStore.user?.id) {
        toast.info(t('users.cannotEditAdminUser'));
      } else {
        setDialogResult({
          user: { ...selectedUser },
          oldValue: selectedUser,
          newGroups: selectedUser.groups || [],
          closeStatus: 0,
        });
        setOpenCreateUserDialog(true);
      }
    }
  };

  // const handleDeleteUser = async () => {
  //   const selectedUser = gAPP_STORE.getUsersStore().gridStore.selectedRow;
  //   if (selectedUser) {
  //     if (selectedUser.id === gAPP_STORE.loginStore.user?.id) {
  //       toast.info(t('users.cannotDeleteAdminUser'));
  //     } else {
  //       if (window.confirm(t('users.deleteConfirm'))) {
  //         await gAPP_STORE.getUsersStore().delete(selectedUser);
  //         await gAPP_STORE.getUsersStore().gridStore.reload();
  //       }
  //     }
  //   }
  // };

  const toolbarDescriptor: IToolbarExDescriptorItem[] = [
    { type: 'text', text: `${t('users.title')}`, pr: 1 },
    { type: 'divider', pl: 1 },
    {
      type: 'button',
      text: 'create',
      icon: <AddIcon />,
      onClick: handleCreateUser,
      pl: 1,
      disabled: gAPP_STORE.loginStore.user?.isAD,
    },
    {
      type: 'button',
      text: 'edit',
      icon: <EditIcon />,
      onClick: handleEditUser,
      pl: 1,
      disabled: gAPP_STORE.getUsersStore().gridStore.selectedRow ? false : true,
    },
    // {
    //   type: 'button',
    //   text: 'delete',
    //   icon: <ClearRoundedIcon />,
    //   onClick: handleDeleteUser,
    //   color: 'red',
    //   pl: 1,
    // },
  ];

  return (
    <Panel>
      <ToolbarEx descriptor={toolbarDescriptor} />
      <Box display="flex" flexGrow={1}>
        <DataGrid
          id="UsersGrid"
          cols={gAPP_STORE.getUsersStore().gridCols}
          gridStore={gAPP_STORE.getUsersStore().gridStore}
          isNeedTooltipForContent={true}
          noPaging
        />
      </Box>

      <UserDialog
        open={openUserDialog}
        onClose={handleCloseUserDialog}
        setDialogResult={setDialogResult}
        dialogResult={dialogResult}
      />
    </Panel>
  );
};

export default observer(UsersGrid);
