import { IWordDictionary } from 'components/wordDictionary/WordDictionary.types';
import { cloneDeep } from 'lodash';
import { makeAutoObservable, runInAction } from 'mobx';

import { valueToWords } from './../wordDictionary/wordDictionaryUtils';
import { IFilterData } from './i-filter';
import { IFilterPhrase, IFilterStoreData } from './types';

import { gAPP_STORE } from '@/app/app-store';
const DEFAULT_DATA: IFilterStoreData = {
  currentFilterData: {},
  topicList: [],
};

export class FilterStore {
  data: IFilterStoreData = cloneDeep(DEFAULT_DATA);

  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  onFilterFieldChange(fieldName: keyof IFilterData) {
    runInAction(() => {
      this.data.currentFilterData[fieldName] = cloneDeep(this.data.currentFilterData[fieldName]);
    });
  }

  /**
   * Set current filter data
   * @param f
   */
  async setFilterData(f: IFilterData): Promise<void> {
    const topicIds = (f.topic?.value as string[]) || [];
    const topicList: IWordDictionary[] = [];
    for (const id of topicIds) {
      const wd = await gAPP_STORE.getWordDictionaryStore().get(id);
      if (wd) {
        topicList.push(wd);
      }
    }

    runInAction(() => {
      this.data = {
        topicList,
        currentFilterData: f,
      };
    });
  }

  setTopicList(tl: IWordDictionary[]) {
    runInAction(() => {
      this.data.topicList = tl;
    });
  }

  get Data() {
    return this.data.currentFilterData;
  }

  getFilterWords(translation: boolean): string[] {
    const filterWords: string[] = translation
      ? (this.data.currentFilterData.translateIdx?.value as string[]) || []
      : (this.data.currentFilterData.textIdx?.value as string[]) || [];

    const topicWords: string[] = [];
    this.data.topicList.forEach(t => {
      topicWords.push(...valueToWords(t.value));
    });

    return [...filterWords, ...topicWords];
  }

  getSeparatedFilter(translation: boolean) {
    const filter = this.getFilterWords(translation)
      .map(w => w.trim().toLowerCase())
      .filter(w => w.length > 0);
    if (filter.length === 0) return { filterWords: [], filterPhrase: [] };

    const filterWords: string[] = [];
    const filterPhrase: IFilterPhrase[] = [];
    filter.forEach(value => {
      const text = value.trim().toLowerCase();
      if (text.includes(' ')) {
        const words = text.split(' ');
        filterPhrase.push({ text, words });
      } else {
        filterWords.push(text);
      }
    });
    filterWords.sort((a, b) => a.length - b.length);
    filterPhrase.sort((a, b) => a.words.length - b.words.length);

    return { filterWords, filterPhrase };
  }
}
