import {
  Box,
  Checkbox,
  Chip,
  Collapse,
  FormControl,
  FormControlLabel,
  makeStyles,
  MenuItem,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { runInAction } from 'mobx';
import { FC, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { DialogStore } from '../dialog.store';

import { Stack } from './Stack';

import appColors from '@/app/app-colors';
import { gAPP_STORE } from '@/app/app-store';
import { dialogUploadRecordsRestrictions } from '@/common/constants';
import { capitalizeFirstLetter } from '@/common/utils';
import { checkTrim } from '@/components/records/utils/checkTrim';
import { IWordDictionary } from '@/components/wordDictionary/WordDictionary.types';
import { ukColors } from '@/react-ui-kit/src';

const useStyles = makeStyles(theme => ({
  formControl: {},
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  option: {
    padding: 5,
  },
  list: {
    width: '100%',
    position: 'relative',
    overflow: 'auto',
    maxHeight: 125,
    border: '1px solid #ccc',
  },
  input: {
    border: 'none',
    outline: 'none',
    width: '100%',
  },
  textField: {
    border: 'none',
    outline: 'none',
  },
}));

export interface IItemLanguages {
  data: DialogStore;
}

export const ItemLanguages: FC<IItemLanguages> = ({ data }) => {
  const { t } = useTranslation();
  const { t: lang } = useTranslation('lang');
  const classes = useStyles();
  const theme = useTheme();
  const [multi, setMulti] = useState(data.multiLanguageRecognition);
  const [selectedLang, setSelectedlang] = useState(data.language);
  const [selectedDicts, setSelectedDicts] = useState(data.newWordDictionaryList);
  const [tmp, setTmp] = useState('');
  const [words, setWords] = useState(data.somewords);

  const [langBlockDisabled, setLangBlockDisabled] = useState(false);

  const changeLang = useCallback(
    (value: string) => {
      runInAction(() => {
        if (words.length > 0) {
          setWords([]);
          data.clearWords();
        }
        if (selectedDicts.length > 0) {
          setSelectedDicts([]);
          data.setWordDictionaryList([]);
        }
        data.setLanuage(value);
        setSelectedlang(value);
      });
    },
    [data, selectedDicts.length, words.length],
  );

  //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  // галочка Мультиязычное распознавание речи
  const hChangeMulti = useCallback(
    (value: boolean) => {
      changeLang('auto');
      data.setMultiLanguageRecognition(value);
      setMulti(value);
    },
    [data, changeLang],
  );
  //MultiLanguageRecognition
  const blockAutoCheckBox = (
    <FormControlLabel
      label={t('records.multiLanguageRecognition')}
      control={
        <Checkbox checked={multi} disabled={langBlockDisabled} onChange={event => hChangeMulti(event.target.checked)} />
      }
    />
  );

  //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  // выбор языка
  const blockSelectLang = (
    <TextField
      label={t('uploadForm.selectLang')}
      disabled={data.multiLanguageRecognition}
      variant="filled"
      fullWidth
      select
      value={selectedLang}
      onChange={event => changeLang(event.target.value as string)}
    >
      {gAPP_STORE.autoLanguageDetection && (
        <MenuItem key={'auto'} value="auto">
          <Box color="green">{t('records.autoLanguageDetection')}</Box>
        </MenuItem>
      )}
      {gAPP_STORE
        .getLanguagesStore()
        .data.languages.filter(l => l.installed)
        .map((l, index) => (
          <MenuItem key={index} value={l.name}>
            {lang(gAPP_STORE.tServiceLangNameToISO(l.name)?.code || capitalizeFirstLetter(l.name))}
          </MenuItem>
        ))}
    </TextField>
  );

  //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  //выбор словарей на выбранном языке
  const curentLangCode = gAPP_STORE.tServiceLangNameToISO(data.language)?.code;
  const dictToSelect = gAPP_STORE
    .getWordDictionaryStore()
    .data.wordDictionaryList.filter(wd => wd.isAutoAsr && wd.language && curentLangCode === wd.language);
  const dictSelectDisabled = data.multiLanguageRecognition || (dictToSelect.length === 0 && data.language === 'auto');
  const onDeleteDict = useCallback(
    (index: number) => {
      const dicts = selectedDicts.filter((f, i) => i !== index);
      data.setWordDictionaryList(dicts);
      setSelectedDicts(dicts);
    },
    [data, selectedDicts],
  );

  const hChangeDicts = useCallback(
    (value: IWordDictionary[]) => {
      if (value.length > dialogUploadRecordsRestrictions.MAX_TOPICS_COUNT) {
        toast.warn(t('uploadForm.maxTopics', { max_topics: dialogUploadRecordsRestrictions.MAX_TOPICS_COUNT }));
      }
      const newValue = value.slice(0, dialogUploadRecordsRestrictions.MAX_TOPICS_COUNT);
      data.setWordDictionaryList(newValue);
      setSelectedDicts(newValue);
    },
    [data, t],
  );

  const blockDicts = (
    <Stack spacing={0}>
      <Stack direction="row" alignItems="center" spacing={0}>
        <Typography>{t('uploadForm.dictionaries')}</Typography>
      </Stack>
      <FormControl variant="outlined" fullWidth className={classes.formControl}>
        <Autocomplete
          multiple
          clearText={t('uploadForm.clear')}
          disabled={dictSelectDisabled}
          style={{
            background: dictSelectDisabled ? ukColors.lightGrey : undefined,
            border: `1px solid ${theme.palette.grey[200]}`,
            borderRadius: 1,
            gap: 0.5,
          }}
          options={dictToSelect}
          getOptionLabel={option => option.name}
          getOptionSelected={(option, value) => option.id === value.id}
          value={selectedDicts}
          filterSelectedOptions
          noOptionsText={t('uploadForm.noDictionary')}
          renderInput={params => <TextField {...params} variant="outlined" />}
          renderTags={(value, getTagProps) =>
            value.map((wordDictionary, index) => {
              return (
                <Chip
                  color="primary"
                  style={{ backgroundColor: theme.palette.primary.main }}
                  size="small"
                  label={wordDictionary.name}
                  onDelete={() => onDeleteDict(index)}
                  {...getTagProps({ index })}
                />
              );
            })
          }
          renderOption={(wordDictionary: IWordDictionary, props) => {
            return (
              <span {...props} style={{ color: appColors.primary }}>
                {wordDictionary.name}
              </span>
            );
          }}
          onChange={(_event, newValue) => hChangeDicts(newValue)}
        />
      </FormControl>
    </Stack>
  );

  //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  // добавление списка слова
  const wordsSelectDisabled = data.multiLanguageRecognition || data.language === 'auto';
  const ref = useRef<HTMLInputElement>();
  const onEnterKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'Enter') {
        const tmp = ref.current?.value ?? '';
        if (tmp !== '') {
          setWords([...words, tmp]);
          data.addWord(ref.current?.value ?? '');
        }
        setTmp('');
      } else if (event.key === 'Escape') {
        setTmp('');
      }
    },
    [data, words],
  );
  const onDeleteWord = useCallback(
    (index: number) => {
      setWords(words.filter((f, i) => i !== index));
      data.excludeWord(index);
    },
    [data, words],
  );

  const emptyMenu: string[] = [];
  const saveWords = useCallback(
    (value: string[]) => {
      if (value.some(wrd => !checkTrim(wrd))) {
        toast.warn(t('uploadForm.trimInvalid'));

        return;
      }
      if (value.some(wrd => wrd.length > dialogUploadRecordsRestrictions.MAX_LETTERS_COUNT)) {
        toast.warn(t('uploadForm.maxLetters', { value: dialogUploadRecordsRestrictions.MAX_LETTERS_COUNT }));

        return;
      }
      if (value.some(wrd => wrd.length > dialogUploadRecordsRestrictions.MAX_LETTERS_COUNT)) {
        toast.warn(t('uploadForm.maxLetters', { value: dialogUploadRecordsRestrictions.MAX_LETTERS_COUNT }));

        return;
      }
      if (value.some(wrd => wrd.length < dialogUploadRecordsRestrictions.MIN_LETTERS_COUNT)) {
        toast.warn(t('uploadForm.minLetters', { value: dialogUploadRecordsRestrictions.MIN_LETTERS_COUNT }));

        return;
      }
      if (value.length > dialogUploadRecordsRestrictions.MAX_WORDS_COUNT) {
        toast.warn(t('uploadForm.maxWords', { max_words: dialogUploadRecordsRestrictions.MAX_WORDS_COUNT }));

        return;
      }

      const newValue = value.slice(0, dialogUploadRecordsRestrictions.MAX_WORDS_COUNT);

      data.changeWords(newValue);
      setWords(newValue);
    },
    [data, t],
  );

  const blockWords = (
    <Stack spacing={0}>
      <Stack direction="row" alignItems="center" spacing={0}>
        <Typography>{t('uploadForm.additionalwords')}</Typography>
      </Stack>
      <FormControl variant="outlined" fullWidth className={classes.formControl}>
        <Autocomplete
          clearText={t('uploadForm.clear')}
          noOptionsText={t('uploadForm.noLanguages')}
          freeSolo={true}
          disablePortal={false}
          value={words}
          options={emptyMenu}
          multiple
          disabled={wordsSelectDisabled}
          style={{
            background: dictSelectDisabled ? ukColors.lightGrey : undefined,
            borderRadius: 1,
            border: `1px solid ${theme.palette.grey[200]}`,
            gap: 0.5,
          }}
          getOptionSelected={(option, value) => option === value}
          renderInput={params => <TextField {...params} variant="outlined" />}
          renderTags={(value, getTagProps) =>
            value.map((word, index) => {
              return (
                <Chip
                  color="primary"
                  style={{ backgroundColor: theme.palette.error.main }}
                  size="small"
                  label={word}
                  onDelete={() => onDeleteWord(index)}
                  {...getTagProps({ index })}
                />
              );
            })
          }
          onChange={(_event, newValue) => {
            saveWords(newValue);
          }}
          onKeyDown={onEnterKeyDown}
          autoComplete={false}
          onBlur={() => setWords(data.somewords)}
        />
      </FormControl>
    </Stack>
  );

  const blockLang = (
    <Stack>
      {gAPP_STORE.multiLanguageRecognition && blockAutoCheckBox}
      {blockSelectLang}
    </Stack>
  );
  const blockDictsAndWords = (
    <Collapse in={gAPP_STORE.avocado_wordsDictionary}>
      <Stack spacing={2}>
        {blockDicts}
        {blockWords}
      </Stack>
    </Collapse>
  );

  return (
    <Stack spacing={2}>
      {blockLang}
      {blockDictsAndWords}
    </Stack>
  );
};
