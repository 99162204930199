import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Checkbox, Chip, FormControl, FormControlLabel, FormGroup } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { Autocomplete } from '@material-ui/lab';
import { IGroup } from 'components/groups';
import { observer } from 'mobx-react';
import React, { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { makeStyles } from '@material-ui/core/styles';
import { CommonDialog, CommonDialogUiStore, ECommonDialogCloseStatus, useForceUpdate } from '@uk';

import { useTranslation } from 'react-i18next';

import { cloneDeep } from 'lodash';

import { checkTrim } from '../records/utils/checkTrim';

import { IUser } from './i-user';

import { gAPP_STORE } from '@/app/app-store';

import { userSettingsRestrictions } from '@/common/constants';
const useStyles = makeStyles(theme => ({
  formControl: {},
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  option: {
    padding: 5,
  },
}));

interface IFormValues {
  name: string;
  login: string;
  password?: string;
}

interface IUserDialogBodyProps {
  dialogResult: IUserDialogResult;
  setDialogResult: (res: IUserDialogResult) => void;
  setResetCb: (cb: () => void) => void;
  setFormValuesGetter: (v: () => () => IFormValues) => void;
  setIsValid: (v: boolean) => void;
  isEditing: boolean;
}

const DialogBody = observer((props: IUserDialogBodyProps) => {
  const { dialogResult, setDialogResult, setResetCb, setFormValuesGetter, setIsValid, isEditing } = props;
  const selectedUser = gAPP_STORE.getUsersStore().gridStore.selectedRow;

  const { t } = useTranslation();
  const trimTest = useMemo(() => ({ name: 'checkTrim', test: checkTrim, message: t('users.trimError') }), [t]);
  const formSchema = useMemo(
    () =>
      yup.object({
        name: yup
          .string()
          .required(t('users.requiredField'))
          .test(trimTest)
          .max(userSettingsRestrictions.MAX_NAME_LENGTH, t('users.lengthError'))
          .min(userSettingsRestrictions.MIN_NAME_LENGTH, t('users.lengthError')),
        login: yup
          .string()
          .required(t('users.requiredField'))
          .test(trimTest)
          .test({
            name: 'uniqueLogin',
            message: t('users.mustBeUniqueLogin'),
            test: v =>
              !gAPP_STORE
                .getUsersStore()
                .gridStore.data.map(user => user.email.toLocaleUpperCase())
                .includes(v.toLocaleUpperCase()) || selectedUser?.email.toLocaleUpperCase() === v.toLocaleUpperCase(),
          })
          .max(userSettingsRestrictions.MAX_LOGIN_LENGTH, t('users.lengthError'))
          .min(userSettingsRestrictions.MIN_LOGIN_LENGTH, t('users.lengthError')),
        password: isEditing
          ? yup.string().test(function (value) {
              if (!value) return true;
              const max = value?.length > userSettingsRestrictions.MAX_PASSWORD_LENGTH;
              const min = value?.length < userSettingsRestrictions.MIN_PASSWORD_LENGTH;

              if (max) {
                return this.createError({ message: t('users.lengthError') });
              }
              if (min) {
                return this.createError({ message: t('users.lengthError') });
              }

              return true;
            })
          : yup
              .string()
              .required(t('users.requiredField'))
              .test(trimTest)
              .max(userSettingsRestrictions.MAX_PASSWORD_LENGTH, t('users.lengthError'))
              .min(userSettingsRestrictions.MIN_PASSWORD_LENGTH, t('users.lengthError')),
      }),
    [isEditing, selectedUser?.email, t, trimTest],
  );
  const classes = useStyles();
  const forceUpdate = useForceUpdate();
  const defaultValues = useMemo(
    () => ({
      name: dialogResult.user.firstName || '',
      login: dialogResult.user.email || '',
      password: dialogResult.password || '',
    }),
    [dialogResult.password, dialogResult.user.email, dialogResult.user.firstName],
  );

  const {
    register,
    reset,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(formSchema),
    defaultValues,
    mode: 'onChange',
  });
  useEffect(() => {
    setIsValid(isValid);
  }, [isValid, setIsValid]);

  useEffect(() => {
    setResetCb(reset);
  }, [reset, setResetCb]);

  useEffect(() => {
    setFormValuesGetter(() => getValues);
  }, [getValues, reset, setFormValuesGetter]);

  const userDlgUiStoreRef = useRef<CommonDialogUiStore>(new CommonDialogUiStore());
  useEffect(() => {
    userDlgUiStoreRef.current.setOkEnabled(isValid);
  }, [isValid]);

  //TODO: костыль с any, нужно поставить нормальный тип

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleActiveChecked = (event: any) => {
    const newValue = cloneDeep(dialogResult);
    newValue.user.active = event.target.checked;
    setDialogResult(newValue);
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleIsAdminChecked = (event: any) => {
    const newValue = cloneDeep(dialogResult);
    newValue.user.isAdmin = event.target.checked;
    setDialogResult(newValue);
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleIsSupervisorChecked = (event: any) => {
    const newValue = cloneDeep(dialogResult);
    newValue.user.isSupervisor = event.target.checked;
    setDialogResult(newValue);
  };
  const handleIsEditorChecked = (event: ChangeEvent<HTMLInputElement>) => {
    dialogResult.user.isEditor = event.target.checked;
    const newValue = cloneDeep(dialogResult);
    newValue.user.isEditor = event.target.checked;
    setDialogResult(newValue);
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any

  const handleChangeGroups = (_: React.ChangeEvent<unknown>, newValue: IGroup[]) => {
    if (newValue.length > userSettingsRestrictions.MAX_GROUPS_COUNT) {
      toast.warn(t('users.maxGroups', { max_groups: userSettingsRestrictions.MAX_GROUPS_COUNT }));
    }
    const clone = cloneDeep(dialogResult);
    clone.newGroups = newValue.slice(0, userSettingsRestrictions.MAX_GROUPS_COUNT);
    setDialogResult(clone);

    //TODO: явно какой-то костыль, нужно проверить и исправить
    forceUpdate();
  };

  return (
    <Box width={600}>
      <FormGroup>
        {/* User name */}
        <FormControl variant="outlined" fullWidth className={classes.formControl}>
          <TextField
            disabled={gAPP_STORE.loginStore.user?.isAD}
            key="name"
            label={t('users.name')}
            variant="outlined"
            fullWidth
            defaultValue={dialogResult.user.firstName}
            autoComplete="off"
            error={Boolean(errors.name)}
            helperText={errors.name?.message}
            {...register('name', { required: true })}
          />
        </FormControl>
        <Box p={3} />

        {/* User login */}
        <FormControl variant="outlined" fullWidth className={classes.formControl}>
          <TextField
            disabled={gAPP_STORE.loginStore.user?.isAD}
            key="login"
            label={t('users.login')}
            variant="outlined"
            fullWidth
            defaultValue={dialogResult.user.email}
            autoComplete="login"
            error={Boolean(errors.login)}
            helperText={errors.login?.message}
            {...register('login', { required: true })}
          />
        </FormControl>
        <Box p={1} />

        {/* User password */}
        <FormControl variant="outlined" fullWidth className={classes.formControl}>
          <TextField
            disabled={gAPP_STORE.loginStore.user?.isAD}
            key="password"
            label={t('password')}
            variant="outlined"
            fullWidth
            autoComplete="new-password"
            error={Boolean(errors.password)}
            helperText={errors.password?.message}
            {...register('password', { required: true })}
          />
        </FormControl>
        <Box p={1} />

        <Box p={1} />

        {/* User role and status */}
        <Box display="flex" flexDirection="row" justifyContent="flex-start">
          <Box pl={1}>
            <FormControlLabel
              control={
                <Checkbox
                  disabled={gAPP_STORE.loginStore.user?.isAD}
                  color="primary"
                  defaultChecked={dialogResult.user.active}
                  onChange={handleActiveChecked}
                />
              }
              label={t('users.active')}
            />
          </Box>
        </Box>
        <Box p={1} />

        <Box display="flex" flexDirection="row" justifyContent="flex-start">
          <Box pl={1}>
            <FormControlLabel
              control={
                <Checkbox
                  disabled={gAPP_STORE.loginStore.user?.isAD}
                  color="primary"
                  defaultChecked={dialogResult.user.isAdmin}
                  onChange={handleIsAdminChecked}
                />
              }
              label={t('users.admin')}
            />
          </Box>
          <Box p={1} />

          <Box pl={1}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  defaultChecked={dialogResult.user.isSupervisor}
                  onChange={handleIsSupervisorChecked}
                />
              }
              label={t('users.supervisor')}
            />
          </Box>

          <Box pl={1}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  defaultChecked={dialogResult.user.isEditor}
                  onChange={handleIsEditorChecked}
                />
              }
              label={t('users.editor')}
            />
          </Box>
        </Box>

        <Box p={2} />

        {/* Groups */}
        {gAPP_STORE.groups && (
          <FormControl variant="outlined" fullWidth className={classes.formControl}>
            <Autocomplete
              multiple
              options={gAPP_STORE.getGroupsStore().data.groups}
              clearText={t('uploadForm.clear')}
              noOptionsText={t('uploadForm.noGroups')}
              getOptionLabel={option => option.name}
              getOptionSelected={(option, value) => option.id === value.id}
              value={dialogResult.newGroups || []}
              filterSelectedOptions
              renderInput={params => <TextField {...params} label={t('users.groups')} variant="outlined" />}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => <Chip color="primary" label={option.name} {...getTagProps({ index })} />)
              }
              onChange={handleChangeGroups}
            />
          </FormControl>
        )}

        <Box p={1} />
      </FormGroup>
    </Box>
  );
});

export interface IUserDialogResult {
  user: IUser;
  oldValue?: IUser;
  newGroups: IGroup[];
  closeStatus: ECommonDialogCloseStatus;
  password?: string;
  passwordConfirm?: string;
}

interface IUserDialogProps {
  open: boolean;
  dialogResult: IUserDialogResult;
  setDialogResult: (res: IUserDialogResult) => void;
  onClose: (dialogResult: IUserDialogResult) => void;
}

const UserDialog: React.FC<IUserDialogProps> = ({ open, dialogResult, onClose, setDialogResult }) => {
  const { t } = useTranslation();
  const isEditing = !!dialogResult.oldValue;
  const userDlgUiStore = useMemo(() => new CommonDialogUiStore(), []);
  const [formValuesGetter, setFormValuesGetter] = useState<(() => IFormValues) | undefined>();
  const [isValid, setIsValid] = useState<boolean>(false);
  const [resetCb, setResetCb] = useState<() => void | undefined>();

  const handleClose = (status: number) => {
    if (!formValuesGetter) return;
    const { login, password, name } = formValuesGetter();
    const valueCopy = cloneDeep(dialogResult);
    valueCopy.closeStatus = status;

    if (status === ECommonDialogCloseStatus.CANCEL) {
      resetCb?.();
      setDialogResult(valueCopy);
      onClose(valueCopy);

      return;
    }

    if (status === ECommonDialogCloseStatus.OK && isValid) {
      valueCopy.password = password;
      valueCopy.passwordConfirm = password;
      valueCopy.user.email = login;
      valueCopy.user.firstName = name;
      setDialogResult(valueCopy);

      if (onClose) {
        onClose(valueCopy);
      }
    }
  };

  useEffect(() => {
    userDlgUiStore.setOkEnabled(isValid);
  }, [isValid, userDlgUiStore]);

  return (
    <CommonDialog
      title={t('user')}
      open={open}
      onClose={handleClose}
      contentComponent={() => (
        <DialogBody
          setIsValid={setIsValid}
          setResetCb={setResetCb}
          dialogResult={dialogResult}
          setFormValuesGetter={setFormValuesGetter}
          setDialogResult={setDialogResult}
          isEditing={isEditing}
        />
      )}
      autotestId={'user'}
      uiStore={userDlgUiStore}
    />
  );
};

export default observer(UserDialog);
