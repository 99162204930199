import { action, computed, makeAutoObservable, observable, runInAction } from 'mobx';
import { toast } from 'react-toastify';

import { IUser } from 'components/users/i-user';
import { BackendService } from 'services';

import { EStoreStatus } from '../../common/store-status';

import { gAPP_STORE } from '@/app/app-store';
import { gridProxyService } from '@/services/grid-proxy/grid-proxy-service';
import { BackendError, HandleErrorTranslatedMessage } from '@/services/types';
import { LOCAL_STORAGE_USER_KEY } from '@/common/constants';

export class LoginStore {
  user: IUser | undefined = undefined;
  login = '';
  password = '';
  status: EStoreStatus = EStoreStatus.EMPTY;
  smsCode = '';
  @observable
  mfaConfig?: boolean;

  @observable
  timeout?: number;

  error = '';
  cacheKey?: string;

  constructor(cacheKey?: string) {
    this.cacheKey = cacheKey;

    makeAutoObservable(this, undefined, { autoBind: true });
  }

  @computed getAuthorizationString = () =>
    `Basic ${btoa(unescape(encodeURIComponent(`${this.login}:${this.password}`)))}`;

  @action checkUserSession = () => {
    const userSession = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_KEY) || '{}');
    if (userSession.user && Date.now() - userSession.datetime < 1000 * 60 * 30) {
      this.user = userSession.user;
      this.saveUserSession();
    }
  };

  @action getAuthorizeJwtToken = async (token: string) => {
    try {
      const data = await BackendService.get(`user/jwt/auth?token=${token}`, undefined, false);

      if (data.userEntry === undefined) {
        this.signOut();
      } else {
        runInAction(() => {
          this.user = data.userEntry;
          if (this.user && data.isAdIntegration) {
            this.user.isAD = true;
          }
        });
        this.saveUserSession();
      }
    } catch {
      this.signOut();
      this.checkUserSession();
    }
  };

  generateJwtToken = async () => {
    const token = await BackendService.get('user/jwt/generate', undefined, false);

    return token;
  };

  @action updateGroups = async () => {
    if (!this.user) return;
    try {
      const data = await BackendService.post('groups/users', JSON.stringify([this.user.id]));

      this.user.groups = data[this.user.id];
    } catch (error) {
      this.user.groups = undefined;
      if (error instanceof BackendError) {
        if (error.status !== 204) {
          toast.error(HandleErrorTranslatedMessage(error));
        } else {
          this.user.groups = [];
        }
      } else {
        console.error(`Server error: ${JSON.stringify(error)}`);
      }
    }
  };

  async mfaInit() {
    try {
      const data = await BackendService.get('auth/mfa/config', undefined, false, false, undefined, false, false);
      if (data) {
        this.setMfaConfig(data.isMfaEnabled, data.timeout);
      }
    } catch (error) {
      console.log(error);
    }
  }

  @action
  setMfaConfig(value: boolean, timeout?: number) {
    this.mfaConfig = value;
    this.timeout = timeout;
  }

  /**
   * generateSMSCode
   */

  @action generateSMSCode = async (login: string) => {
    try {
      this.login = login;

      const response = await BackendService.post(
        'auth/mfa/generate',
        JSON.stringify({ username: login }),
        false,
        false,
        undefined,
        '',
        undefined,
        false,
      );

      if (response === 200) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.error('Error in generateSMSCode:', e);

      return false;
    }
  };

  /**
   * Login
   */
  @action
  signIn = async (login: string, smsCode: string, password: string) => {
    try {
      this.clearUserSession();
      gAPP_STORE.setStatusCodeError(0);

      this.error = '';
      this.login = login;
      this.smsCode = smsCode;
      this.password = password;
      this.status = EStoreStatus.LOADING;

      const requestBody: { username: string; password: string; code?: string } = {
        username: login,
        password: this.password,
      };

      if (this.smsCode) {
        requestBody.code = this.smsCode;
      }

      if (this.smsCode) {
        requestBody.code = this.smsCode;
      }

      const data = await BackendService.post(
        'auth',
        JSON.stringify(requestBody),
        false,
        false,
        undefined,
        '',
        undefined,
        false,
      );

      if (data.userEntry !== undefined) {
        runInAction(() => {
          this.user = data.userEntry;
          if (this.user && data.isAdIntegration) {
            this.user.isAD = true;
          }
        });
        this.status = EStoreStatus.SUCCESS;
        this.saveUserSession();
      }

      gAPP_STORE.getRecordTextStore().restoreState();

      return true;
    } catch (error) {
      console.error('LoginStore, signIn(), error = ', JSON.stringify(error));

      this.error = JSON.stringify(error);
      this.user = undefined;
      this.status = EStoreStatus.ERROR;
    }

    return false;
  };

  @computed isSignedIn = () => this.user !== undefined;

  @action signOut = () => {
    BackendService.get('logout', undefined, true, true, undefined, false, false); //!!! чистит cookie SESSION !!!!
    this.clearUserSession();
  };

  restoreUserSession = async () => {
    if (this.cacheKey) {
      try {
        gAPP_STORE.dataGridAnswer = await gridProxyService.getGidAnswer(this.cacheKey);

        if (gAPP_STORE.dataGridAnswer) {
          await this.getAuthorizeJwtToken(gAPP_STORE.dataGridAnswer.Jwt);

          return;
        }
      } catch (e) {
        console.error('restoreUserSession:', e);
      }
    } else {
      this.checkUserSession();
    }
  };

  saveUserSession = () => {
    localStorage.setItem(
      LOCAL_STORAGE_USER_KEY,
      JSON.stringify({
        user: this.user,
        datetime: Date.now(),
      }),
    );
  };

  @action clear() {
    this.user = undefined;
    this.login = '';
    this.password = '';
    this.status = EStoreStatus.EMPTY;
  }
  clearUserSession = () => {
    this.clear();
    localStorage.removeItem(LOCAL_STORAGE_USER_KEY);
  };
}
